<template>
  <div class="box">
    <div class="content">
      <div class="main">
        <div class="lunbo">
          <el-carousel :interval="3000" arrow="always" height="320px" indicator-position="none">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
              <div class="title">{{item.journalisTitle}}</div>
              <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails('',item.id)"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <dl v-for="(item,index) in subjectList" :key="index" class="dl" :class="'dl_'+index">
          <dt class="dt">
            <span>{{item.navigationName}}</span>
            <span class="more" @click="more(item.navigationName,item.id,item.navigationType)">更多></span>
          </dt>
          <template v-for="(dates,idxs) in item.journalism" @key="idxs">
            <dd v-if="idxs<8" class="dd" @click="goDetails('',dates.id)" :key="idxs">
              <span class="name">{{dates.journalisTitle}}</span>
              <span class="time">{{toDate(dates.releaseTime,"yyyy-MM-dd")}}</span>
            </dd>
          </template>
        </dl>
        <ul class="picBox partners">
          <div class="titleBox">
            <span>精彩图片</span>
            <span class="more" @click="more('精彩图片','img','0')">更多></span>
          </div>
          <template v-for="(item,index) in mapDepotList" @key="index">
            <li v-if="index<5" :class="'li_'+index" :key="index">
              <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails('',item.id)"></el-image>
            </li>
          </template>
        </ul>
        <ul class="partners">
          <div class="titleBox">合作伙伴</div>
          <div class="containt">
            <ul :style="{ left: calleft + 'px', width: widthData + 'px' }" v-on:mouseover="stopMove()" v-on:mouseout="move()" class="imgBoxoul">
              <li v-for="(item, index) in linksList2" :key="index" ref="lis">
                <el-image class="img" :src="echoImg(item.imgUrl)" @click="openLinks('', item.linkUrl)" fit="fit"></el-image>
              </li>
              <li v-for="(dates, idx) in linksList2" :key="'c_' + idx" ref="lis1">
                <el-image class="img" :src="echoImg(dates.imgUrl)" @click="openLinks('', dates.linkUrl)" fit="fit"></el-image>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { selectLink,selectMapDepot,selectBanner,selectList,getTextarea } from "@/api/template";
// import AboutUs from "./aboutUs";
import { mapState } from "vuex";
export default {
  name: "Index",
  // components: { AboutUs },
  data() {
    return {
      linksList: [],
      mapDepotList: [],
      linksList1:[],
      linksList2:[],
      bannerList: [],
      subjectList: [],
      synopsis:'',
      introduce:'',
      calleft: 0,
      speed: 1,
      timer: '',
      lyList:['../images/gg1.jpg','../images/gg2.jpg']
    };
  },
  created(){
    this.init()
    this.move()
  },
  computed: {
    widthData(){
      return 216 * Number(this.linksList2.length * 2)
    },
    ...mapState(["color"]),
  },
  mounted() {
    // let imgBox = document.getElementsByClassName("imgBoxoul")[0];
    // imgBox.innerHTML += imgBox.innerHTML;
  },
  methods: {
    init(){
      this.getSelectBanner()
      this.getSelectList()
      this.getSelectMapDepot()
      this.getSelectLink()
    },
    getSelectLink(){
      let self = this
      selectLink().then(res =>{
        if(res.code == 200){
          res.data.forEach(item =>{
            if(!item.imgUrl){
              this.linksList1.push(item)
            }else if(item.imgUrl){
              this.linksList2.push(item)
            }
          })
          this.linksList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectBanner(){
      let self = this
      selectBanner().then(res =>{
        if(res.code == 200){
          this.bannerList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          this.mapDepotList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectList(){
      let self = this
      selectList().then(res =>{
        if(res.code == 200){
          this.subjectList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    openLinks(linkUrl){
      window.open(linkUrl,'_blank')
    },
    more(navigationName,id,type){
      this.$router.push({params: {name:navigationName,id:id,type: type},name:'ninth-list'})
    },
    goDetails(navigationName,id) {
      let data = {
        name: navigationName,
        id: id,
      }
      window.sessionStorage.setItem('detailsValue', JSON.stringify(data))
      this.$router.push('/ninth-details')
    },
    getTextareaFun(journalisContent,type){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        if(type == 'synopsis'){
          this.synopsis = res.data.dataHtml
        }
        if(type == 'introduce'){
          this.introduce = res.data.dataHtml
        }
      })
    },
    move() {
      this.timer = setInterval(this.starMove, 20);
    },
    //开始移动
    starMove() {
      this.calleft -= 1.2;//*this.speed
      if (this.calleft <= -this.linksList2.length*216) {
        this.calleft = 0;
      }
    },
    //鼠标悬停时停止移动
    stopMove() {
      clearInterval(this.timer);
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
  },
};
</script>
<style lang="scss" scoped>
  .content{
    position: relative;
    .main{
      width: 1000px;
      margin: 10px auto 0;
      .lunbo{
        position: relative;
        width: 640px;
        height: 320px;
        display: inline-block;
        cursor: pointer;
        float: left;
        .title{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          background: rgba(0,0,0,0.6);
          z-index: 99;
          color: #fff;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
        }
        .img{
          width: 100%;
          height: 100%;
        }
        /deep/ .el-carousel__arrow{
          background-color:rgba(31,45,61,.4);
        }
        /deep/.el-carousel__indicators{
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      .dl{
        float: left;
        height: 320px;
        background: #fff;
        width: 490px;
        margin-bottom: 10px;
        margin-right: 20px;
        .dt{
          position: relative;
          width: 460px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #e5e5e5 ;
          font-size: 16px;
          font-weight: bold;
          color: #333;
          padding: 0 15px;
          .more{
            position: absolute;
            top: 0;
            right: 0;
            width: 90px;
            height: 50px;
            line-height: 50px;
            background: #e50014;
            color: #fff;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
          }
        }
        .dd{
          padding: 0 15px;
          height: 32px;
          line-height: 32px;
          border-bottom: 1px dashed #e5e5e5;
          cursor: pointer;
          .name{
            display: inline-block;
            width: 75%;
            height: 32px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time{
            display: inline-block;
            width: 25%;
            text-align: right;
            vertical-align: top;
          }
        }
        .dd:last-child{
          border-bottom: none;
        }
      }
      .dl_0{
        width: 360px;
        .dt{
          width: 330px;
        }
      }
      .dl:nth-child(even){
        margin-right: 0;
      }
      .picBox{
        clear: both;
        margin-top: 20px;
        .titleBox{
          position: relative;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #e5e5e5 ;
          font-size: 16px;
          font-weight: bold;
          color: #333;
          padding: 0 15px;
          background: #fff;
          margin-bottom: 10px;
        }
        .more{
          position: absolute;
          top: 0;
          right: 0;
          width: 90px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          background: #e50014;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }
        li{
          float: left;
          width: 242px;
          height: 116px;
          vertical-align: top;
          margin-bottom: 10px;
          margin-left: 8px;
          .img{
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
        .li_0{
          width: 500px;
          height: 243px;
          margin-left: 0;
          //margin-right: 16px;
        }
      }
      .partners{
        clear: both;
        margin-top: 20px;
        .titleBox{
          position: relative;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #e5e5e5 ;
          font-size: 16px;
          font-weight: bold;
          color: #333;
          padding: 0 15px;
          background: #fff;
          margin-bottom: 10px;
        }
        .containt {
          position: relative;
          overflow-y: auto;
          width: 100%;
          height: 168px;
          overflow: hidden;
          margin: 0 auto;
          ul {
            margin: 0 auto;
            width: 100%;
            position: absolute;
            left: 0px;
            cursor: pointer;
            z-index: 10;
            overflow: hidden;
            li {
              float: left;
              height: 120px;
              padding: 30px 48px 0;
              overflow: hidden;
              text-align: center;
              .img{
                height: 100%;
                cursor: pointer;
                background: #fff;
              }
            }
          }
        }
      }
    }
  }
</style>
